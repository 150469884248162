import { useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { kurentoManager } from 'services/Kurento/KurentoManager';
import styles from './CameraStream.module.css';
import AiqDialog from 'components/UI/Dialogs/AiqDialog';
import CircularLoader from 'components/UI/Loaders/CircularLoader';
import CameraStreamFooter from './cameraStreamFooter/CameraStreamFooter';
import CameraStreamButtons from './cameraStreamButtons/CameraStreamButtons';
import CameraStreamHeader from './cameraStreamHeader/CameraStreamHeader';
import { logInfo } from 'utils/utils';
import StreamStateNotification from './streamStateNotifications/StreamStateNotification';
import RampActions from '../../transactionSideBar/rampActions/RampActions';

import DigitalZoomWraper from '../utils/DigitalZoom';

export default function CameraStream({
    camConfig,
    transactionInfo,
    tabId,
    expandedCamId,
    expandCamera,
    tabAudioConfig,
    level1,
    L2ContainerId,
    expandedL2ContainerId,
    handleRestartVideoStream,
    handleVideoStarted,
    toggleAudio,
    enableDemoAudioFile,
    isFullScreenRequested,
    fullScreenElementId,
    footerMode,
    transactionDetectionCamera,
    l1CamerasId,
    isTabActive,
    videoWallCameras,
    transactionDetectionAgentInformation,
}) {
    const [isStreamActivated, setIsStreamActivated] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [streamState, setStreamState] = useState({ info: '', error: '', freezed: '', isLoading: false });
    const { id: cameraPosition, camId: cameraId, gateCameraId, motionDetectionCamera, gateCameraId: gateCameraIdDetection } = camConfig;
    const { streamType } = camConfig.videoData;
    const isExpanded = expandedCamId === camConfig.id;
    const streamTypeShow = isExpanded ? 'MAIN' : streamType;
    const videoOutputId = kurentoManager.getVideoElementId(tabId, cameraPosition);
    const { availableAudioStreams } = tabAudioConfig;
    // const { resetTransform } = useControls();

    const { id: transactionId } = transactionInfo || {};

    useEffect(() => {
        const videoElement = document.getElementById(videoOutputId);
        const callback = (e) => {
            const { action, ...stateUpdate } = e.detail;
            setStreamState((state) => ({ ...state, ...stateUpdate }));
            action === 'hardRefresh' && hardRefreshVideoStreamHandler();
        };
        videoElement.addEventListener('videoStreamState', callback);
        return () => videoElement.removeEventListener('videoStreamState', callback);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isStreamActivated && isStreamActivated !== 'activating') {
            setIsStreamActivated('activating');
            kurentoManager.activate(cameraId, streamType, videoOutputId, tabId, transactionId, isTabActive).then(() => {
                setIsStreamActivated(true);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isStreamActivated, isTabActive]);

    useLayoutEffect(() => {
        if (isStreamActivated === true && isTabActive) kurentoManager.playStreamType(streamTypeShow, videoOutputId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTabActive, isStreamActivated]);

    const expandCameraHandler = function () {
        expandCamera && expandCamera(camConfig.id, L2ContainerId, false, streamType);
    };

    const toggleAudioHandler = useCallback(
        function (e) {
            if (!availableAudioStreams.includes(videoOutputId)) return;
            kurentoManager.enableAudio(videoOutputId, e?.muteAll);
            toggleAudio && toggleAudio(videoOutputId, tabId, e?.muteAll);
        },
        [availableAudioStreams, videoOutputId, tabId, toggleAudio]
    );

    const videoStartedHandler = function () {
        console.log(`Video element ${videoOutputId} onPlaying cameraId=${cameraId}`);

        if (streamState.updateSourceState) streamState.updateSourceState({ info: '', error: '', isLoading: false });
        handleVideoStarted && handleVideoStarted(cameraPosition, tabId);
    };

    const refreshVideoStreamHandler = function () {
        logInfo(`[STREAM REFRESH] refreshType="STANDARD"`, { transactionInfo, camConfig, camVideoId: videoOutputId });
        kurentoManager.restart(videoOutputId, streamTypeShow);
        handleRestartVideoStream && handleRestartVideoStream(cameraPosition, tabId);
    };

    const hardRefreshVideoStreamHandler = function () {
        logInfo(`[STREAM REFRESH] refreshType="HARD"`, { transactionInfo, camConfig, camVideoId: videoOutputId });
        kurentoManager.hardRefresh(videoOutputId);
    };

    const openDialogHandler = function () {
        setOpenDialog(true);
    };
    const closeDialogHandler = function () {
        setOpenDialog(false);
    };
    const elementId = `${transactionId ? `${transactionId}-` : ''}${cameraPosition}`;
    const cameraHasTransaction = transactionDetectionCamera?.gate_camera_id === gateCameraIdDetection;
    const hasRelays = camConfig.relays;
    const relays =
        hasRelays && camConfig.relays.map((relayConfig) => <RampActions key={relayConfig.relayId} config={relayConfig} classes={styles.relays} />);

    //styles
    //
    //
    const cameraStreamContainerStyles = `${styles['tc-cam-views']} ${motionDetectionCamera ? ` ${styles['tc-cam-views--motion']}` : ''}${
        expandedCamId && isExpanded ? ` ${styles['tc-cam-views--expanded']}` : ''
    }${
        expandedCamId && !level1 && expandedL2ContainerId === L2ContainerId && expandedCamId !== camConfig.id
            ? ` ${styles['tc-cam-views--hidden']}`
            : ''
    }${fullScreenElementId === elementId ? ` ${styles['tc-cam-views--fullscreen']}` : ''}`;

    const cameraStreamButtonsStyles = `${l1CamerasId ? styles['showing-buttons-videoWall'] : ''}`;
    const videoContainerStyles = `${styles['video-container']}${isExpanded && hasRelays ? ` ${styles['video-container--left']}` : ''}`;
    const videoStyles = getVideoStyles(expandedCamId, camConfig, level1);
    const streamStateInfoStyles = `${styles['stream-state-info']} ${
        streamState.info && !streamState.error ? styles['stream-state-info--pulse'] : styles.hidden
    }`;
    const errorText = camConfig.status === 'INACTIVE' ? 'Turned off in Control Center' : streamState.error || 'Error loading camera stream';

    //

    return (
        <>
            <div
                id={elementId}
                className={cameraStreamContainerStyles}
                onMouseEnter={() => {
                    !footerMode && setHovered(true);
                }}
                onMouseLeave={() => !footerMode && setHovered(false)}
            >
                <CameraStreamHeader
                    hovered={hovered || footerMode}
                    isVideoLoading={streamState.isLoading}
                    videoFailedToLoad={!!streamState.error}
                    camConfig={camConfig}
                    level1={level1}
                    tabAudioConfig={tabAudioConfig}
                    tabId={tabId}
                    expandedCamId={expandedCamId}
                    isFullScreenRequested={isFullScreenRequested}
                    headerMode={l1CamerasId}
                    cameraHasTransaction={cameraHasTransaction}
                    transactionDetectionAssigned={cameraHasTransaction && transactionDetectionAgentInformation?.[gateCameraIdDetection]}
                ></CameraStreamHeader>
                <CameraStreamButtons
                    cameraId={gateCameraId}
                    className={cameraStreamButtonsStyles}
                    cameraHasTransaction={cameraHasTransaction}
                ></CameraStreamButtons>
                <div className={videoContainerStyles} onDoubleClick={expandCameraHandler}>
                    {streamState.isLoading && !streamState.error && (
                        <CircularLoader containerStyles={{ top: '0.4rem', height: 'calc(100% - 1.5rem)' }} />
                    )}
                    {streamState.error && <StreamStateNotification text={errorText} />}
                    {!streamState.isLoading && !streamState.error && streamState.freezed && <StreamStateNotification text="Frozen" />}

                    <p className={streamStateInfoStyles}>{streamState.info}</p>

                    <div className={videoStyles} id={`video-container__${videoOutputId}`}>
                        <DigitalZoomWraper config={{ disabled: videoWallCameras && !isExpanded }} reset={!isExpanded}>
                            <video id={videoOutputId} onPlaying={videoStartedHandler} autoPlay={true} />
                        </DigitalZoomWraper>
                    </div>
                </div>

                <CameraStreamFooter
                    hovered={hovered || footerMode}
                    isVideoLoading={streamState.isLoading}
                    videoFailedToLoad={!!streamState.error}
                    camConfig={camConfig}
                    refreshVideoStreamHandler={refreshVideoStreamHandler}
                    hardRefreshVideoStreamHandler={hardRefreshVideoStreamHandler}
                    level1={level1}
                    openDialogHandler={openDialogHandler}
                    tabAudioConfig={tabAudioConfig}
                    tabId={tabId}
                    expandedCamId={expandedCamId}
                    onToggleAudio={toggleAudioHandler}
                    enableDemoAudioFile={enableDemoAudioFile}
                    isFullScreenRequested={isFullScreenRequested}
                    footerMode={footerMode}
                    cameraIssues={camConfig.issues}
                    transactionId={transactionId}
                    camInfo={{
                        transactionInfo,
                        camVideoId: videoOutputId,
                        camConfig,
                    }}
                    videoWallCameras={videoWallCameras}
                />

                {isStreamActivated && openDialog && (
                    <AiqDialog
                        title={`${videoOutputId} debug info`}
                        text={kurentoManager.getInfo(videoOutputId)}
                        open={openDialog}
                        onClose={closeDialogHandler}
                    ></AiqDialog>
                )}
                {isExpanded && <div className={styles['relays-container']}>{relays}</div>}
            </div>
        </>
    );
}

function getVideoStyles(expandedCamId, camConfig, level1) {
    return `${styles['tc-cs-video']}${
        expandedCamId ? (expandedCamId === camConfig.id ? ` ${styles['tc-cs-video-expanded']}` : ` ${styles['tc-cs-video-shrinked']}`) : ''
    }${!level1 ? ` ${styles['tc-cs-video--level2']}` : ''}`;
}
