import { useRef, useEffect } from 'react';

function ClickOutside(ref, clickOutsideFunction, isOn = true) {
    useEffect(() => {
        if (isOn) {
            function handleClickOutside(event) {
                ref.current && !ref.current.contains(event.target) && clickOutsideFunction();
            }

            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, isOn]);
}

export default function OutsideAlerter(props) {
    const wrapperRef = useRef(null);
    ClickOutside(wrapperRef, props.clickOutsideFunction, props.isOn);

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }} ref={wrapperRef}>
            {props.children}
        </div>
    );
}
