import { notificationHandlerSync } from 'utils/utils';
import { axiosAiq } from 'config';

export const keyDownPTZHandler = async function (e, camId) {
    const key = e.key;
    if (isNaN(key)) return;
    try {
        const response = await axiosAiq.put(`/camera/${camId}/ptz/presets/${key}`);
        if (response.status === 404) {
            notificationHandlerSync({ title: `Preset ${key} is not available. Please select a different preset` });
            return;
        }
    } catch (err) {
        notificationHandlerSync({ err, title: `Failed to move camera to preset ${e.key}. Please check the connection and try again.` });
    }
};
