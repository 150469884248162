import { useEffect, useState } from 'react';
import styles from './Automation.module.css';
import TooltipIconButton from 'components/UI/Buttons/TooltipIconButton/TooltipIconButton';
import bugImage from 'assets/bug.png';

function Automation({ disabled, automation, issues, onBugIconClick }) {
    const { source, info, sourceValue } = automation || {};
    const [initial, setInitial] = useState(true);
    const [automationOverWritten, setAutomationOverWritten] = useState(false);

    useEffect(() => {
        if (!source || !sourceValue) return;
        if (initial) {
            setInitial(false);
            return;
        }
        setAutomationOverWritten(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source, info, sourceValue]);

    const containerStyles = `${styles['automation-container']}${disabled ? ` ${styles['automation-container--disabled']}` : ''}`;

    return (
        <div className={containerStyles}>
            <div className={styles['automation-container--source']}>
                {issues && (
                    <TooltipIconButton
                        clickHandler={onBugIconClick}
                        iconButtonConfig={{ tabIndex: '-1' }}
                        iconImage={bugImage}
                        title="Report Issue"
                    />
                )}
                <p className={`${automationOverWritten || source === 'alisa' ? ` ${styles['automation-overwritten']}` : ''}`}>{source}</p>
                <p>{info}</p>
            </div>
            <div className={styles['automation-container--value']}>
                <p>{sourceValue}</p>
            </div>
        </div>
    );
}

export default Automation;
