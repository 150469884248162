import styles from './TooltipIconButton.module.css';
import { Tooltip } from '@mui/material';

function TooltipIconButton({ clickHandler, classes, iconImage, title }) {
    return (
        <Tooltip title={title}>
            <img
                onClick={() => clickHandler(true)}
                className={`${styles['tc-cs-footer--camera-icon']} ${styles[classes]}`}
                alt="report"
                src={iconImage}
            />
        </Tooltip>
    );
}

export default TooltipIconButton;
